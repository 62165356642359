.App {
  text-align: center;
}

.banner {
  width:100%
}


.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
  margin-left:30px;
  margin-right:30px;
} 

.App-link {
  color: #61dafb;
}
